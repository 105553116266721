import { template as template_4d9c7d7b7d9f47f886dfce00dd78bdbe } from "@ember/template-compiler";
const FKLabel = template_4d9c7d7b7d9f47f886dfce00dd78bdbe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
